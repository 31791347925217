import {
    Bullets,
    Pagination,
} from "ui-components";
import "./JobList.scss";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { companyGetJobList } from "../../service/Company/company";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import { capitalizeFirstLetter, convertDate, handleKeyPressSearch, setInQueryParams } from "../../utils/Common/Constant";
import FilterSection from "../../components/FilterSection/FilterSection";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import { CompanyListEnums } from "../../utils/Enums/CompanyListEnums";
import ShimmerLoader from "../../components/ShimmerLoader/ShimmerLoader";

const JobsList = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const navigate = useNavigate();
    const [checkboxFilter,] = useState<string>(
        t("JOBS.SCANNING_ACTIVE"),
    );
    const [jobListData, setJobListData] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const searchParam = searchParams.get("search") || "";
    const [isResetDisable, setIsResetDisable] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const jobListPaginaitonRef = useRef<HTMLDivElement>(null);
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    const { companyId } = useParams();
    const sortBy = searchParams.get("sortBy") ? searchParams.get("sortBy") : "createdAt";
    const sortDir = searchParams.get("sortDir") ? searchParams.get("sortDir") : "desc"
    const status = searchParams.get("st") || CompanyListEnums.STATUS_ACTIVE;
    const jobsPaginationRef = useRef<HTMLDivElement>(null)
    const [listLoading, setListLoading] = useState(true)
    const statusOptions = [
        { value: CompanyListEnums.STATUS_ACTIVE, label: t("GENERAL.ACTIVE"), checked: status === CompanyListEnums.STATUS_ACTIVE },
        { value: CompanyListEnums.STATUS_INACTIVE, label: t("GENERAL.INACTIVE"), checked: status === CompanyListEnums.STATUS_INACTIVE },
    ];
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (jobListPaginaitonRef.current && managePaginationRef != null) {
            jobListPaginaitonRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);
    useEffect(() => {
        getJobList();
    }, [checkboxFilter, page, searchParam, status, sortBy, sortDir]);
    const getJobList = async () => {
        const companyGetJobListRes = await companyGetJobList(
            companyId || "",
            capitalizeFirstLetter(status),
            page,
            pageSize,
            sortBy || "",
            sortDir || "",
            searchParam
        );
        if (companyGetJobListRes?.status === 200) {
            setJobListData(companyGetJobListRes?.data?.jobList);
            setTotalCount(companyGetJobListRes?.data?.totalCount);
            setListLoading(false)
        } else {
            setListLoading(false)
        }
    };
    // reset all filters
    const clearAllFilter = () => {
        const url = `/${currentLanguage}/jobs/${companyId}`;
        disableResetBtn()
        navigate(url, { replace: true });
    };

    const disableResetBtn = () => {
        const containsLastActiveAsc =
            queryParams.get("sortBy") === null &&
            sortDir === CompanyListEnums.DESCENDING;
        const companyStatus = status === CompanyListEnums.STATUS_ACTIVE;
        const isSearchText = searchParam === "";
        setIsResetDisable(
            containsLastActiveAsc &&
            companyStatus &&
            isSearchText
        );
    }
    useEffect(() => {
        disableResetBtn()
    }, [searchParam, status, sortBy, sortDir]);

    // function when filter click
    const handleFilterClick = (filterType: string) => {
        setInQueryParams(queryParams, "sortBy", filterType, navigate);
        setInQueryParams(queryParams, "page", 1, navigate);
        if (sortDir === "desc") {
            setInQueryParams(queryParams, "sortDir", "asc", navigate);
        } else {
            setInQueryParams(queryParams, "sortDir", "desc", navigate);
        }
    };
    const sortingFilters = [
        {
            label: t("GENERAL.CREATED_AT"),
            filterKey: CompanyListEnums.FILTER_CREATED_AT,
            isActive: queryParams.get("sortDir"),
        },
    ]
    return (
        <div className="resumeScanningLeftSide companyListWrapper createdAssessmentList">
            <div className="container-fluid mb-4">
                <div className="row">
                    <FilterSection
                        title={t("GENERAL.JOBS")}
                        statusOptions={statusOptions}
                        queryParams={queryParams}
                        navigate={navigate}
                        setInQueryParams={setInQueryParams}
                        searchParam={searchParam}
                        handleKeyPressSearch={handleKeyPressSearch}
                        clearAllFilter={clearAllFilter}
                        isResetDisable={isResetDisable}
                        handleFilterClick={handleFilterClick}
                        pageName="companyList"
                        sortingFilters={sortingFilters}
                        searchPlaceholder={"GENERAL.SEARCH_JOB"}
                    />
                </div>
                <div className="totalContentDiv" ref={jobsPaginationRef}>
                    {totalCount > 0 && (
                        <p className="totalContent">
                            {t("GENERAL.TOTAL_COMPANIES")} : {totalCount ?? "-"}
                        </p>
                    )}
                </div>
                <div className="assessmentListContentSec row">
                    {listLoading ? <ShimmerLoader /> :
                        (jobListData ?? []).length > 0 ? (
                            (jobListData ?? [])?.map((list: any, index: any) => {
                                return (
                                    <div
                                        className="assessmentListCardSec"
                                        key={Math.random() + index}
                                    >
                                        <div className="assessmentListCard">
                                            <div className="assessmentListCardImg cursorPointer">
                                                <AssessmentListingIcon />
                                                <h4>{list?.status ?? "Active"}</h4>
                                                <h6>{convertDate(list?.createdAt, false, true) ?? "-"}</h6>
                                            </div>
                                            <div className="assessmentListCardDetails">
                                                <div className="listCardDetailsTitleSec">
                                                    <div className="listCardDetailsTitle">
                                                        <div className="listItemSec">
                                                            <div className="listCardTitleHeading">
                                                                <div>
                                                                    <div className="clipBoard">
                                                                        <h3
                                                                            title={list?.title ?? ""}
                                                                            className="cursorPointer"
                                                                            onClick={() => { }}
                                                                        >
                                                                            {list?.title
                                                                                ? capitalizeFirstLetter(list?.title)
                                                                                : "-"}
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="bulletSec">
                                                                {list?.jobSkills?.map((item: any, ind: number) => {
                                                                    return (
                                                                        <div key={ind}>
                                                                            <Bullets
                                                                                skillName={capitalizeFirstLetter(
                                                                                    item?.skillName,
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="listContentSkillsSec">
                                                    <div className="listContentSkills decreaseGap">
                                                        <div className="w-100">
                                                            <div className="w-100 mb-3">
                                                                <p>{t("JOBS.SCANNING_EXPERIENCE")}</p>
                                                                <h5>{`${list?.minimumExperience} - ${list?.maximumExperience} years`}</h5>
                                                            </div>
                                                            <div className="w-100 mb-3">
                                                                <p>{t("JOBS.INTEGRATIONS")}</p>
                                                                <h5>{list?.integration ?? "-"}</h5>
                                                            </div>

                                                        </div>
                                                        <div className="w-100">
                                                            <div className="w-100 mb-3">
                                                                <p>{t("JOBS.ASSESSMENTS")}</p>
                                                                <h5>{list?.assesment ?? 0}</h5>
                                                            </div>
                                                            <div className="locationDiv">
                                                                <p>{t("JOBS.SCANNING_LOCATION")}</p>
                                                                <h5>{list?.city ?? "-"}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div>
                                                                <p>{t("JOBS.SCANNING_UPLOADED_CVS")}</p>
                                                                <h5>{list?.uploadedCv ?? "0"}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="noListFound">
                                <DataNotAvailable />
                            </div>
                        )}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Pagination
                            className="pagination-bar"
                            currentPage={page ?? 1}
                            totalCount={totalCount || 0}
                            pageSize={pageSize}
                            onPageChange={(page: number) => {
                                setInQueryParams(queryParams, "page", page, navigate);
                                setManagePaginationRef(Math.random());
                            }}
                            ofLabel={t("GENERAL.OF")}
                            resultLabel={t("GENERAL.RESULTS")}
                            setPageSize={setPageSize}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default JobsList;
