import "./AssessmentsList.scss";
// import UserImage from "../../utils/Images/UserImage";
import { useEffect, useRef, useState } from "react";
import { companyGetAssessments } from "../../service/Assessment/assessment";
import { Pagination, showToast, TemplateSearch } from "ui-components";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertDate, setInQueryParams, truncateString } from "../../utils/Common/Constant";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import BackArrow from "../../utils/Images/BackArrow";
import CreditCoin from "../../utils/Images/CreditCoin";
import ShimmerLoader from "../../components/ShimmerLoader/ShimmerLoader";
import useDebounce from "../../utils/Hooks/useDebounce";

const AssessmentsList = () => {
  const [AssessmentsList, setAssessmentsList] = useState<any>([]);
  const [companyName, setCompanyName] = useState("-")
  const [totalBalance, setTotalBalance] = useState(null)
  const { companyId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const searchCompany = useDebounce(searchText.trim(), 500);
  const [totalQuestions, setTotalQuestions] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const AssessListPaginationRef = useRef<any>(null);
  const queryParams = new URLSearchParams(location.search);
  const [listLoading, setListLoading] = useState(true)
  const navigate = useNavigate();
  const postData = {
    companyId: companyId,
    search: searchCompany,
    filterOptions: {
      status: "",
      hasResponse: "both",
      reviewPending: "both",
      selected: "both",
    },
    currentPage: page,
    pageSize: 10,
  };
  useEffect(() => {
    companyGetAssessmentsHandler(postData)
    window.scrollTo(0, 0);
  }, [searchCompany, page]);

  async function companyGetAssessmentsHandler(postData: unknown) {
    const response = await companyGetAssessments(postData);
    if (response?.status === 200) {
      setTotalQuestions(response?.data?.totalCount);
      setAssessmentsList(response?.data?.data);
      setTotalBalance(response?.data?.totalBalance);
      setCompanyName(response?.data?.companyName)
      setListLoading(false)
    } else {
      showToast(response?.data?.customMessage, "error");
      setListLoading(false)
    }
  }
  useEffect(() => {
    if (AssessListPaginationRef.current && managePaginationRef != null) {
      AssessListPaginationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);
  return (
    <>
      <div className="AssessmentsListWrapper">
        <div className="container-fluid mt-4 mb-4">
          <div className="col-md-12 ">
            <div className="companyNameTitle">
              <div className="backArrow" onClick={() => window.history.back()}>
                <BackArrow />
              </div>
              <div className="companyNameHeading">
                <span>{companyName}</span>
                <span className="d-flex">{t("GENERAL.TOTAL_BALANCE")}: <CreditCoin width={22} height={22} />{" "}{totalBalance}</span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={"assessmentListSearchSec "}
              ref={AssessListPaginationRef}
            >
              <TemplateSearch
                onChange={(text) => {
                  setInQueryParams(queryParams, "page", "1", navigate);
                  setSearchText(text);
                }}
                placeholder={t("GENERAL.SEARCH_ASSESSMENT")}
                iconName="searchList"
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="totalAssess">
              <span>
                {t("GENERAL.TOTAL_ASSESSMENTS")} : {totalQuestions}
              </span>
            </div>
            {listLoading ? <ShimmerLoader /> :
              AssessmentsList?.length ? (
                AssessmentsList.map((assessment: any) => {
                  return (
                    <div className="AssessmentListContentSec" key={Math.random()}>
                      <div className="AssessmentListCard">
                        <div className="AssessmentListCardImg ">
                          <AssessmentListingIcon />
                          <h4 className="text-center">
                            {assessment?.status}
                          </h4>{" "}
                          <h6>
                            {convertDate(
                              AssessmentsList?.createdAt,
                              false,
                              true,
                            ) ?? "-"}
                          </h6>
                        </div>
                        <div className="AssessmentListCardDetails">
                          <div className="ListCardDetailsTitleSec">
                            <div className="ListCardDetailsTitle">
                              <div className="listItemSec">
                                <div className="ListCardTitleHeading">
                                  <div>
                                    <h3
                                      title={assessment?.title}
                                      className="cursorPointer"
                                    >
                                      {truncateString(assessment?.title, 50)}
                                    </h3>
                                    <div className="link_wrap">
                                      <p className="acc_style04">
                                        {assessment?.isPublic
                                          ? "Public"
                                          : "Private"}
                                      </p>
                                    </div>
                                    <b>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: `${assessment?.description}`,
                                        }}
                                      ></p>
                                    </b>

                                    <p>{assessment?.website}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="ListContentSkillsSec">
                                <div className="ListContentSkills decreaseGap">
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.TOTAL_APPLICANTS")}</p>
                                      <h5>
                                        {assessment?.totalApplicaitons ?? "-"}
                                      </h5>
                                    </div>
                                    <div className="w-100">
                                      <p>{t("GENERAL.TOTAL_QUESTIONS")}</p>
                                      <h5>{assessment?.totalQuestions ?? "-"}</h5>
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.CREATED_BY")}</p>
                                      <h5>{assessment?.createdBy ?? "-"}</h5>
                                    </div>
                                    <div className="w-100">
                                      <p>{t("GENERAL.TOTAL_REJECTED")}</p>
                                      <h5>{assessment?.totalRejected ?? "-"}</h5>
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.CREDITS")}</p>
                                      <h5 className="d-flex">
                                        <CreditCoin width={22} height={22} />{" "}
                                        {assessment?.credits ?? "0"}
                                      </h5>
                                    </div>
                                    <div className="w-100">
                                      <p>{t("GENERAL.TOTAL_REVIEWED")}</p>
                                      <h5>{assessment?.totalReviewed ?? "-"}</h5>
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.TOTAL_RESPONSES")}</p>
                                      <h5>{assessment?.totalResponses ?? "-"}</h5>
                                    </div>
                                    <div className="w-100">
                                      <p>{t("GENERAL.TOTAL_SELECTED")}</p>
                                      <h5>{assessment?.totalSelected ?? "-"}</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <DataNotAvailable />
                </div>
              )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                className="pagination-bar"
                currentPage={page ?? 1}
                totalCount={totalQuestions || 0}
                pageSize={pageSize}
                onPageChange={(page: any) => {
                  setInQueryParams(queryParams, "page", page, navigate);
                  setManagePaginationRef(Math.random());
                }}
                ofLabel={t("GENERAL.OF")}
                resultLabel={t("GENERAL.RESULTS")}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentsList;
