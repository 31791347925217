import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    AssessmentButton,
    Pagination,
    showToast,
    TypeSelect,
} from "ui-components";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import {
    capitalizeFirstLetter,
    convertDate,
    setInQueryParams,
    truncateString,
} from "../../utils/Common/Constant";
import EmailIcon from "../../utils/Images/EmailIcon";
import PhoneIcon from "../../utils/Images/PhoneIcon";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import CreditCoin from "../../utils/Images/CreditCoin";
import {
    partnerWithdrawalRequest,
    processWithdrawalRequest,
} from "../../service/Partner/PartnerApis";
import ShimmerLoader from "../../components/ShimmerLoader/ShimmerLoader";
import useDebounce from "../../utils/Hooks/useDebounce";

const WithdrawalRequest = () => {
    const { t } = useTranslation();
    const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const searchParam = searchParams.get("search") || "";
    const debounceSearchParam = useDebounce(searchParam, 500);
    const location = useLocation();
    const [withdrawalRequestLists, setwithdrawalRequestLists] = useState<any>([]);
    const navigate = useNavigate();
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const timeoutRef = useRef<any>(null);
    const queryParams = new URLSearchParams(location.search);
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const withdrawStatus = [
        { label: "All", value: "All" },
        { label: "Success", value: "Success" },
        { label: "Pending", value: "Pending" },
    ];
    const selectedStatus = searchParams.get("status") ?? "All";

    useEffect(() => {
        if (createdAssessPaginationRef.current && managePaginationRef != null) {
            createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);
    useEffect(() => {
        getwithdrawRequestApi();
        return () => clearTimeout(timeoutRef.current);
    }, [
        location.search,
        debounceSearchParam,
        pageSize,
        page,
        managePaginationRef,
        selectedStatus,
    ]);

    // get all withdraw requests
    const getwithdrawRequestApi = async () => {
        try {
            const response = await partnerWithdrawalRequest(
                page,
                pageSize,
                selectedStatus,
            );
            if (response?.status === 200) {
                setwithdrawalRequestLists(response?.data?.data);
                setTotalCount(response?.data?.totalCount);
            } else {
                showToast(response?.data?.customMessage, "error");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    // approve withdraw request
    const processWithdrawalRequestApi = async (partnerId: string) => {
        const data = {
            partnerId: partnerId,
        };
        try {
            const response = await processWithdrawalRequest(data);
            if (response?.status === 200) {
                showToast(response?.customMessage, "success");
                setManagePaginationRef(Math.random() * 10);
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>
            <div className="companyListWrapper createdAssessmentList">
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="CreatedAssessmentListTitle">
                                <h2>{t("GENERAL.WITHDRAWAL_REQUEST")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 mb-5">
                        <TypeSelect
                            options={withdrawStatus}
                            placeholder={t("GENERAL.STATUS")}
                            value={selectedStatus}
                            onChange={(value: string) => {
                                setInQueryParams(queryParams, "status", value, navigate);
                            }}
                            loadingText={t("")}
                            className={"statusDropDown"}
                        />
                    </div>
                    <div className="totalContentDiv">
                        {totalCount > 0 && (
                            <p className="totalContent">
                                {t("GENERAL.TOTAL_WITHDRAWALS")} : {totalCount ?? "-"}
                            </p>
                        )}
                    </div>
                    <div className="row">
                        {isLoading ? (
                            <ShimmerLoader />
                        ) : withdrawalRequestLists?.length ? (
                            withdrawalRequestLists?.map((withdrawRequest: any) => {
                                return (
                                    <div className="AssessmentListContentSec" key={Math.random()}>
                                        <div className="AssessmentListCard">
                                            <div className="AssessmentListCardImg ">
                                                {withdrawRequest?.profilePic ? (
                                                    <div className="profileImage">
                                                        <img
                                                            src={withdrawRequest?.profilePic}
                                                            className="logo-img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <AssessmentListingIcon />
                                                )}
                                                <h4 className="mt-3"> {withdrawRequest?.status}</h4>
                                            </div>
                                            <div className="AssessmentListCardDetails">
                                                <div className="ListCardDetailsTitleSec">
                                                    <div className="ListCardDetailsTitle">
                                                        <div className="listItemSec">
                                                            <div className="ListCardTitleHeading">
                                                                <div>
                                                                    <h3
                                                                        title={
                                                                            withdrawRequest?.displayName &&
                                                                            capitalizeFirstLetter(
                                                                                withdrawRequest?.displayName,
                                                                            )
                                                                        }
                                                                    >
                                                                        {withdrawRequest?.displayName
                                                                            ? capitalizeFirstLetter(
                                                                                truncateString(
                                                                                    withdrawRequest?.displayName,
                                                                                    50,
                                                                                ),
                                                                            )
                                                                            : "-"}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="email-mobile-section">
                                                                {withdrawRequest?.email && (
                                                                    <a
                                                                        href={`mailto:${withdrawRequest?.email}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <EmailIcon /> {withdrawRequest?.email}
                                                                    </a>
                                                                )}
                                                                &nbsp; &nbsp;
                                                                {withdrawRequest?.mobile && (
                                                                    <a href={`tel:${withdrawRequest?.mobile}`}>
                                                                        <PhoneIcon /> {withdrawRequest?.mobile}
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ListContentSkillsSec">
                                                    <div className="ListContentSkills decreaseGap">
                                                        <div className="w-100">
                                                            <div>
                                                                <p>{t("GENERAL.REQUESTED_CREDITS")}</p>
                                                                <h5 className="d-flex">
                                                                    {" "}
                                                                    <CreditCoin width={22} height={22} />
                                                                    {withdrawRequest?.requestedCredits ??
                                                                        withdrawRequest?.requestedCredits}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="w-100">
                                                                <p>{t("GENERAL.CALCULATED_AMOUNT")}</p>
                                                                <h5>
                                                                    {withdrawRequest?.calculatedAmount ??
                                                                        withdrawRequest?.calculatedAmount}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="w-100">
                                                                <p>{t("GENERAL.REQUEST_DATE")}</p>
                                                                <h5>
                                                                    {withdrawRequest?.requestDate
                                                                        ? convertDate(
                                                                            withdrawRequest?.requestDate,
                                                                            false,
                                                                            true,
                                                                        )
                                                                        : "-"}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`RightSideArrowSec ${withdrawRequest?.status === "Success" && "visibilityHide"}`}
                                                    >
                                                        <AssessmentButton
                                                            className="whiteBtn"
                                                            onClick={() =>
                                                                processWithdrawalRequestApi(
                                                                    withdrawRequest?.partnerId,
                                                                )
                                                            }
                                                        >
                                                            {t("GENERAL.APPROVE")}
                                                        </AssessmentButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <DataNotAvailable />
                        )}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: string) => {
                                    setInQueryParams(queryParams, "page", page, navigate);
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithdrawalRequest;
