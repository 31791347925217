import { showToast } from "ui-components";
import { t } from "i18next";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

// ar: Arabic he: Hebrew fa: Persian (Farsi) ur: Urdu
const rtlLanguages = ["ar", "he", "fa", "ur"]; // Add other RTL languages if needed

export const isRtl = (language: string) => rtlLanguages.includes(language);
// check component disability
export const isDisable = (functionBehavior: any) => {
  return functionBehavior === "disable" ? true : false;
};

// check component hidden or not
export const isHide = (functionBehavior: any) => {
  return functionBehavior === "hide" ? false : true;
};

export const addDisableCss = (functionBehavior: any) => {
  return functionBehavior === "disable" ? "addDisableCss" : "";
};

// user login User Data
export const loginUserData = {
  email: "",
  password: "",
};

export function calculateMinusAmount(
  increasingValue: number,
  lastCount: number,
) {
  return lastCount - increasingValue;
}

export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const handleKeyPressSearch = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (e.key === "Enter") {
    e.preventDefault(); // Prevent default form submission on Enter key
  }
};

// text to speak function
export function TextToSpeakMessage(message: string, callback: () => void) {
  const speech: any = new SpeechSynthesisUtterance(message);
  speech.voice = window.speechSynthesis.getVoices()[10];
  speechSynthesis.speak(speech);
  // Call the callback function after the message has been spoken
  speech.onend = callback;
}

export const convertDate = (
  dateTime: string,
  withComma?: boolean,
  withTime?: boolean,
) => {
  const days: { [key: string]: string } = {
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat",
    Sun: "Sun",
  };
  const dateFormat = moment(dateTime).format("llll")?.split(",");
  const year = dateFormat[2]?.split(" ")[1];
  const time =
    dateFormat[2]?.split(" ")[2] + " " + dateFormat[2]?.split(" ")[3];
  const date = dateFormat[1]?.split(" ")[2];
  const month = dateFormat[1]?.split(" ")[1];
  const day = dateFormat[0];
  const timeZoneAbbreviation = moment.tz(moment.tz.guess()).format("z");
  if (withComma) {
    return `${date} ${month} ${year}, ${days[day]}`;
  } else if (withTime) {
    return `${date} ${month} ${year}, ${days[day]} ${time} (${timeZoneAbbreviation})`;
  } else {
    return `${date} ${month} ${year} (${days[day]})`;
  }
};
// copy to clipbord funtion
export const copyToClipboard = (ref: any, url: string, text?: string) => {
  if (ref.current && navigator.clipboard) {
    // Use the Clipboard API
    navigator.clipboard
      .writeText(url)
      .then(() => {
        showToast(text ? text : t("INVITE.COPY_SUCCESS"), "success");
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_err) => {
        showToast(t("INVITE.COPY_FAILED"), "error");
      });
  }
};

//fomate time

export function formatTimestamp(timestamp: any) {
  const date = new Date(timestamp);
  const options: any = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
}

interface Item {
  value: string | number | undefined;
  label: string;
  option?: any;
}

interface Iobject {
  value: string | number | undefined;
  label: string;
}

export async function getLabelByValue(
  arr: Item[],
  targetValue: string | number,
): Promise<Iobject | undefined> {
  const item = arr.find((item) => item.value === targetValue);
  return item ? item : undefined;
}

// decode text
export function convertToText(value: string) {
  if (value === undefined || value === null) {
    return "";
  } else {
    return atob(value);
  }
}

export function extractLanguage(url: string): string | null {
  const parts = url.split("/");
  if (parts.length < 3) {
    return null; // URL doesn't have enough parts
  }
  return parts[1]; // Return the second part of the URL
}

// end edited assessment data

// start generate uuid
export const generateUUID = () => {
  const hexDigits = "0123456789abcdef";
  const segments = [8, 4, 4, 4, 12];

  return segments
    .map((segment) =>
      Array.from({ length: segment }, () =>
        hexDigits.charAt(Math.floor(Math.random() * 16)),
      ).join(""),
    )
    .join("-");
};
// end generate uuid
// start pascal case
export const toPascalCase = (str: string): string => {
  return str.replace(/(\w)(\w*)/g, (_, firstChar, restOfWord) => {
    return firstChar.toUpperCase() + restOfWord.toLowerCase();
  });
};
// end pascal case

// extract path from url
export function extractPathFromUrl(url: string): string | null {
  // eslint-disable-next-line no-useless-escape
  const match = url.match(/\/a\/([^\/?#]+)/);
  return match ? match[0].substring(1) : null;
}

// calculate time in min, hour, month, year ago
export function getTimeDifference(
  inputTime: string,
  minuteText: string,
  hourText: string,
  dayText: string,
  monthText: string,
  yearText: string,
  agoText: string,
  justNowText: string,
) {
  const currentTime = moment();
  if (!inputTime) {
    return undefined;
  }
  const diffMinutes = currentTime.diff(inputTime, "minutes");
  const diffHours = currentTime.diff(inputTime, "hours");
  const diffDays = currentTime.diff(inputTime, "days");
  const diffMonths = currentTime.diff(inputTime, "months");
  const diffYears = currentTime.diff(inputTime, "years");

  if (diffMinutes < 1) {
    return `${justNowText}`;
  } else if (diffMinutes < 60) {
    return `${`${diffMinutes} ${minuteText}`} ${agoText}`;
  } else if (diffHours < 24) {
    return `${`${diffHours} ${hourText}`} ${agoText}`;
  } else if (diffDays < 30) {
    return `${`${diffDays} ${dayText}`} ${agoText}`;
  } else if (diffMonths < 12) {
    return `${`${diffMonths} ${monthText}`} ${agoText}`;
  } else {
    return `${`${diffYears} ${yearText}`} ${agoText}`;
  }
}

// handle error while calling api
export function handleError(error: any) {
  if (error?.customMessage) {
    showToast(error?.customMessage, "error");
  } else {
    handleError(error);
  }
}

// if we have uniqueId then use that otherwise use new
export const foundUniqueId = (id: string | undefined | null) => {
  if (id) {
    return id;
  } else {
    const uniqueId = uuidv4();
    localStorage.setItem("tempUniqueId", uniqueId);
    return uniqueId;
  }
};

export const checkObjectValues = (obj: any): boolean => {
  for (const key in obj) {
    if (obj[key]) {
      return true;
    }
  }
  return false;
};

export const capitalizeFirstLetter = (str: any) => {
  if (typeof str !== "string" || !str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// function to set queryparams when select filters
export const setInQueryParams = (
  queryParams: any,
  key: string,
  value: any,
  navigate: any,
) => {
  queryParams.set(key, value);
  navigate(`?${queryParams.toString()}`, { replace: true });
};

export const s3UploadApis = {
  startupload: "s3Upload/startUpload",
  getPresigned: "s3Upload/generatePresigned",
  completeUpload: "s3Upload/completeUpload",
};

// cut the large string then show ...
export const truncateString = (str: any, num: any) => {
  return str?.length > num ? str?.slice(0, num) + "..." : str;
};

export const titleCase = (str: any) => {
  // Split the string at the hyphen, capitalize each word, and join them back with a space.
  return str
    .split("-") // Split the string at hyphen
    .map(
      (word: any) => word.charAt(0).toLowerCase() + word.slice(1).toLowerCase(),
    )
    .join("-"); // Join the words back with a space
};

// date validations functions
export const currentDate = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD format
export const validateEndDate = (date: string, inputDate: string) => {
  // Ensure end date is greater than start date and not greater than current date
  if (new Date(date) > new Date(currentDate)) return false;
  if (inputDate && new Date(date) < new Date(inputDate)) return false;
  return true;
};
export const validateStartDate = (date: string, inputDate: string) => {
  // Ensure start date is less than end date and not greater than current date
  if (new Date(date) > new Date(currentDate)) return false;
  if (inputDate && new Date(date) > new Date(inputDate)) return false;
  return true;
};

export const ticketsStatus: any = {
  NEW: "New",
  INPROGRESS: "In Progress",
  ONHOLD: "On Hold",
  CLOSED: "Closed",
  RESOLVED: "Resolved",
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const ticketsCreatedBy: any = {
  COMPANY_ADMIN: "Company",
  APPLICANT: "Applicant",
  PARTNER_ADMIN: "Partner",
  ADMIN: "Admin",
};
